const DEFAULT_CONSENT = {
  'ANALYTICS': "DENY",
  'MARKETING': "DENY",
  'PERSONALIZATION': "DENY",
  'ESSENTIAL': "ACCEPT",
  'OPT-OUT': "DENY",
  'STORAGE': "DENY"
}

export const getOsanoConsent = () => {
  if (import.meta.client && 'Osano' in window && window.Osano.cm?.getConsent) {
    try {
      return window.Osano.cm.getConsent();
    } catch (error) {
      console.error('Error getting Osano consent:', error);

      return DEFAULT_CONSENT;
    }
  }

  return DEFAULT_CONSENT;
};