<template>
  <div
    :class="{ disabled }"
    data-component-name="Forms.GDPR.GLOBAL"
  >
    <AppCheckbox
      :model-value="modelValue"
      name="terms"
      :id
      @update:model-value="checkboxClickHandler"
    />

    <p>
      <label
        :for="id"
        :class="{ error }"
        @click="labelClickHandler"
      >
        {{ $t('allow_policy') }}
      </label>

      <i18n-t keypath="according_terms">
        <LinkPrivacyNotice />
      </i18n-t>
    </p>
  </div>
</template>

<script setup lang="ts">
// components
import AppCheckbox from '~/components/redesign/AppCheckbox.vue';
import LinkPrivacyNotice from '~/components/redesign/LinkPrivacyNotice.vue';

const props = withDefaults(
  defineProps<{
    error?: string
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
);

const modelValue = defineModel('modelValue', {
  default: false,
});

const id = useId();

const checkboxClickHandler = (value: boolean) => {
  if (!props.disabled) {
    modelValue.value = value;
  }
};

const labelClickHandler = (e: Event) => {
  if (props.disabled) {
    e.preventDefault();
  }
};
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/functions/token";

[data-component-name="Forms.GDPR.GLOBAL"] {
  @include flex-start-start;
  gap: 0.25rem;

  transition: opacity 0.15s ease;

  p {
    @include caption;

    label {
      display: block;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &.error {
        @include caption;
        color: token('error');
      }
    }

    a {
      color: token('link');
      text-decoration: underline;
    }
  }

  &.disabled {
    opacity: 0.25;
    user-select: none;

    *:not(a) {
      cursor: not-allowed;
    }
  }
}
</style>
